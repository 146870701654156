import React, { ReactNode } from 'react';

import styles from './hiddenFromView.module.scss';

export interface HiddenFromViewProps {
  /**
   * The Hidden From View contents
   */
  children: ReactNode;
}

/**
 * HiddenFromView is used to visually hide content on a screen for sighted users but make it available
 * to screen reader users. Read more about visually hiding content on [WebAim](https://webaim.org/techniques/css/invisiblecontent/#techniques).
 */
const HiddenFromView = ({ children }: HiddenFromViewProps) => (
  <span className={styles.container}>{children}</span>
);

export default HiddenFromView;
