import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import Box from '../Box';

import styles from './accordionTransition.module.scss';

interface Props {
  /**
   * Accordion panel content to expand/collapse
   */
  children: ReactNode;
  /**
   * Whether or not the accordion panel is expanded
   */
  isExpanded?: boolean;
}

// If we need to make the transition duration based on the height so that it's
// not too fast for large content, we can follow the example from Material-UI:
// https://github.com/mui-org/material-ui/blob/1c09e678aeaf951f4748ad06361ffe200dc3534a/packages/material-ui/src/Collapse/Collapse.js#L115-L117
const TRANSITION_DURATION = 400;

const AccordionTransition = ({ children, isExpanded }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  // returns a function that will set the container to the specified
  // height when called
  const getContainerHeightSetter =
    (height: string) => (containerEl: HTMLElement) => {
      containerEl.style.setProperty('height', height);
    };
  const setContainerToWrapperHeight = (containerEl: HTMLElement) => {
    const wrapperHeight = wrapperRef.current?.clientHeight ?? 0;

    getContainerHeightSetter(`${wrapperHeight}px`)(containerEl);
  };

  return (
    <CSSTransition
      in={isExpanded}
      appear
      timeout={TRANSITION_DURATION}
      classNames={{
        // "appear" is for initial render. we want it to immediately look like
        // the expanded state (no animation)
        appear: styles.expanded,
        appearDone: styles.expanded,
        enter: styles.expand,
        enterDone: styles.expanded,
        exit: styles.collapse,
      }}
      onEntering={setContainerToWrapperHeight}
      onEntered={getContainerHeightSetter('auto')}
      onExit={setContainerToWrapperHeight}
      onExiting={getContainerHeightSetter('0')}
    >
      <Box
        className={styles.container}
        style={{ transitionDuration: `${TRANSITION_DURATION}ms` }}
      >
        <Box className={styles.wrapper} ref={wrapperRef}>
          <Box className={styles['wrapper-inner']}>{children}</Box>
        </Box>
      </Box>
    </CSSTransition>
  );
};

export default AccordionTransition;
