import React, { forwardRef, ReactElement, Ref } from 'react';
import Box from '../Box';
import ResponsiveBase from '../ResponsiveBase';
import logger from '../_internal/logger';
import { MarginProps } from '../_internal/spacing';
import { NonResponsiveValue, ResponsiveValue } from '../_internal/styling';

type Columns = number | number[];
type GridSpacing = 'none' | 'tight' | 'standard' | 'wide' | number;

const SPACING_TIGHT = 0.5;
const SPACING_STANDARD = 1;
const SPACING_WIDE = 1.5;

const normalizeSpacing = (spacing: GridSpacing): string => {
  if (spacing === 'none') {
    return '0';
  }
  if (spacing === 'tight') {
    return `${SPACING_TIGHT}rem`;
  }
  if (spacing === 'standard') {
    return `${SPACING_STANDARD}rem`;
  }
  if (spacing === 'wide') {
    return `${SPACING_WIDE}rem`;
  }

  return `${spacing}rem`;
};

const sum = (array: number[]): number =>
  array.reduce((total, num) => total + num, 0);

const validateColumns = (columns: Columns): Columns => {
  if (typeof columns === 'number') {
    if (columns < 1 || columns > 12 || !Number.isInteger(columns)) {
      logger.warn(
        `The Grid "columns" prop must be an integer between 1 - 12. Specified value: ${columns}.`,
      );

      return 1;
    }
  } else if (Array.isArray(columns)) {
    const allValidIntegers = columns.every(
      numGridColumns => numGridColumns > 1 && Number.isInteger(numGridColumns),
    );

    if (!allValidIntegers || sum(columns) !== 12) {
      logger.warn(
        `The Grid "columns" prop array must be integers greater than 1 and summing to 12. Specified value: ${columns}.`,
      );

      return 1;
    }
  }

  return columns;
};

const validateSpacing = (spacing: GridSpacing | GridSpacing[]) => {
  if (Array.isArray(spacing)) {
    if (spacing.length !== 2) {
      logger.warn(
        `The Grid "spacing" prop array must be an array of two spacing values. Specified value: ${spacing}.`,
      );
    }

    // Only return the first two elements in the array [row, col]
    return spacing.map(normalizeSpacing).splice(0, 2).join(' ');
  }

  return normalizeSpacing(spacing);
};

interface Props {
  /**
   * One or more elements to horizontally lay out in columns. Strings must be wrapped in `<Fragment>` components.
   */
  children: ReactElement | ReactElement[];
  /**
   * The number of columns to show in a row.
   * A single value defines evenly sized columns, while an array enables defining unevenly sized columns.
   *
   * @default 1
   */
  columns?: ResponsiveValue<Columns>;
  /**
   * The spacing or gutter size between columns and rows. Numeric values are converted to `rem` units.
   * A single value defines evenly spaced rows and columns, while an array enables defining custom `[row, column]` spacing.
   *
   * @default 'standard'
   */
  // Ideal type for `spacing` array would be `[GridSpacing, GridSpacing]`
  // but there is an issue with the current version of the proptypes parser
  // which requires us to use `GridSpacing[]`.
  // This should be updated if future versions of the parser supports the ideal format.
  spacing?: ResponsiveValue<GridSpacing | GridSpacing[]>;
}

export type GridProps = Props & MarginProps;

/**
 * Grids can be applied to pages to help align components to one another. Using a consistent underlying grid for layouts creates a more polished and usable experience.
 */
const Grid = forwardRef(
  (
    { children, columns, m, mt, mb, my, ml, mr, mx, spacing }: GridProps,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const marginProps = { m, mt, mb, my, ml, mr, mx };
    const properties = {
      gap: {
        responsiveValue: spacing,
        defaultValue: 'standard',
        mapCssValue: (val: NonResponsiveValue) =>
          validateSpacing(val as GridSpacing),
      },
      gridTemplateColumns: {
        responsiveValue: columns,
        defaultValue: 1,
        mapCssValue: (val: NonResponsiveValue) => {
          const validatedColumns = validateColumns(val as Columns);

          // when `gridTemplateColumns` is an array it will look something like
          // "8fr 4fr" which will properly distribute the 12 grid columns among
          // the display columns. when it is a number it represents how many
          // columns we want to show and those columns will be divided evenly.
          return Array.isArray(validatedColumns)
            ? validatedColumns.map(numColumns => `${numColumns}fr`).join(' ')
            : `repeat(${validatedColumns}, 1fr)`;
        },
      },
    };

    return (
      <ResponsiveBase properties={properties}>
        {({ className, style }) => (
          <Box
            className={className}
            data-testid="grid-container"
            display="grid"
            ref={ref}
            style={style}
            {...marginProps}
          >
            {children}
          </Box>
        )}
      </ResponsiveBase>
    );
  },
);

export default Grid;
